<div id="cp_close" (click)="cpDismiss()" *ngIf="order && pageType!='modal'">
  &larr; {{closeTxt? closeTxt:'Back to store'}}
</div>

<br>
<div class="container-fluid body justify-content-center mt-2" *ngIf="order"
     [class.p-3]="pageType=='web'">

  <div class="row pt-3  justify-content-center" *ngIf="pageType=='mobile'">
    <img src="{{order?.businessProfile.logo}}" alt="" class="mt-3 mb-1" style="max-height:100px;max-width: 88%;">

  </div>
  <div class="card p-3 h-100 border-0 mt-2"
       [class.box-shadow-card]="pageType=='web'"
       [class.shadow-card]="pageType=='modal'"
       [class.blue-shadow-card]="pageType=='mobile'">
    <div class="row">
      <div [ngClass]="pageType != 'mobile'? 'col-6':'col-12'">
        <h4 class="d-flex">{{order?.currency + ' ' + (toPrice(order?.netTotal)| number: '.2')}}</h4>
        <label class="details-tp">Due on {{getKeyValue('due_date')}}</label>
        <h6 class="d-flex derails-value">Invoice #{{getKeyValue('invoice_number')}}</h6>
      </div>
      <div class="col-6 text-center" *ngIf="pageType !='mobile'">
        <img src="{{order?.businessProfile.logo}}" alt="" class="mt-1" style="max-height:100px;max-width: 100%;">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-2 details-tp d-table">To:</div>
      <div class="col derails-value">{{getKeyValue('to_name')}}</div>
    </div>
    <div class="row">
      <div class="col-2 details-tp d-table">Refer:</div>
      <div class="col derails-value"> {{getKeyValue('refer_name')}}</div>
    </div>
  </div>
  <hr *ngIf="pageType =='modal'" class="mt-2 ml-1 mr-1" style="opacity: inherit">
  <br *ngIf="pageType =='web'">
  <div class="card p-3 h-100 border-0"
       [class.box-shadow-card]="pageType=='web'"
       [class.shadow-card]="pageType=='modal' || pageType=='mobile'">
    <app-payment-view [url]="order?.orderApiUrl"
                      [buttonText]="order?.currency + ' ' + (toPrice(order?.netTotal)| number: '.2')"
                      (onError)="onErrorPayment($event)" (onSuccess)="onSuccessPayment($event)"
                      (openThreeDS)="openThreeDS()">

    </app-payment-view>
    <div *ngIf="pageType =='modal' || pageType =='mobile'">
      <ng-template [ngTemplateOutlet]="body"></ng-template>
    </div>
  </div>
  <div *ngIf="pageType =='web'" class="mt-4">
    <ng-template [ngTemplateOutlet]="body"></ng-template>
  </div>
</div>

<ng-template #body>
  <div class="row mt-3">
    <div class="col-12">
      <p class="font-grey-2 support-text">
        If you need any help or support please contact <a href="mailto:merchant@clearpay.com" target="_blank" >merchant@clearpay.com</a>
    </div>
  </div>
  <div class="row text-center">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-center">
        <span class="font-grey-2 powered-by">Powered By</span> <img
        src="assets/logo.png" alt="" class="clearpay-logo mt-auto">
      </div>
    </div>
  </div>
</ng-template>
