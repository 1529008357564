import { Component, OnInit } from '@angular/core';
import {URL} from "../../utils/URL";
import {Params} from "../../utils/Params";
import {Price} from "../../utils/Price";
import {MaintenanceService} from "../../service/maintenance/maintenance.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {ToastService} from "../../service/toast/toast.service";
import {NetError} from "../../utils/net-error";
import {throws} from "assert";

@Component({
  selector: 'app-paylink',
  templateUrl: './paylink.component.html',
  styleUrls: ['./paylink.component.scss']
})
export class PaylinkComponent implements OnInit {

  isPaymentError = false;
  isPaid = false;
  isLoading = true;
  paylink: any = null;
  order: any = null;
  isError = false;
  isMaintenance = false;
  PAYLINK_NOT_FOUND = false;

  constructor(private maintenanceService:MaintenanceService,private toastService: ToastService) { }

  ngOnInit(): void {
    this.fetchOrder();
  }

  fetchOrder(): any {
    fetch(URL.getPaylink(Params.get('i')))
      .then(response => {
        this.maintenanceService.maintenance(null);
        this.isMaintenance  = false;

        if (response.status != 200) {
          this.isError = true;

          if (response.status == 0 || response.status == 502) {
            this.toastService.showError('Something went wrong with our servers',5000);
          }

          if (response.status == 503) {
            this.isMaintenance  = true;
          }
        }

        return response;
      })
      .then(response => response.json())
      .then((data: any) => {
        this.isLoading = false;
        if(this.isError){
          this.PAYLINK_NOT_FOUND = data.type == 'PAYLINK_NOT_FOUND' || data.type == 'PAYLINK_EXPIRED';
          if(this.isMaintenance){
            this.maintenanceService.maintenance(data.message);
          }
        }else {
          this.paylink = data.pay_link;
          this.order = data.order;
          if (this.isOrderCompleted()) {
            this.isPaid = true;
          }
        }
      }).catch(err => {
      this.isLoading = false;
      this.isError = true;
      console.log(err);
    });
  }

  onErrorPayment(errors: any) {
    this.isPaymentError = true;
  }

  onSuccessPayment(data: any) {
    this.isPaid = true;
  }

  isOrderCompleted() {
    return this.order.status == "COMPLETED";
  }

  toPrice(amount: any) {
    return Price.toPrice(amount);
  }

  sanitizeName(extracName: any) {
    if (extracName == 'gateway_charge') {
      return 'Convenience fee';
    }
    return extracName;
  }

  getDueDate() {
    let dueDate = this.paylink.payLinkMeta.filter((i: any) => i.key == 'due_date');
    if (dueDate.length > 0) {
      return dueDate[0].value;
    }
    return null;
  }

  getToName() {
    return this.paylink.payLinkMeta.filter((i: any) => i.key == 'receiver_name')[0].value;
  }

  getToContact() {
    return this.paylink.payLinkMeta.filter((i: any) => i.key == 'receiver_contact')[0].value;
  }

  getToEmail() {
    return this.paylink.payLinkMeta.filter((i: any) => i.key == 'receiver_email')[0].value;
  }

  getImage() {
    return this.paylink.template.templateMetas.filter((i: any) => i.key == 'image')[0].value;
  }

  getName() {
    return this.paylink.template.name;
  }

  getDescription() {
    return this.paylink.template.description;
  }



}
