import {Component, OnInit} from '@angular/core';
import {URL} from "../../utils/URL";
import {Params} from "../../utils/Params";
import {Price} from "../../utils/Price";
import {NetError} from "../../utils/net-error";
import {MaintenanceService} from "../../service/maintenance/maintenance.service";
import {ToastService} from "../../service/toast/toast.service";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  isPaymentError = false;
  isPaid = false;
  isLoading = true;
  invoice: any = null;
  order: any = null;
  isError = false;
  showInvoice = false;
  INVOICE_NOT_FOUND = false;
  isMaintenance = false;

  constructor(private maintenanceService:MaintenanceService,private toastService: ToastService) {
    this.fetchOrder();
  }

  ngOnInit(): void {
  }

  fetchOrder(): any {
    fetch(URL.getInvoice(Params.get('i')))
      .then(response => {
        this.maintenanceService.maintenance(null);
        this.isMaintenance  = false;

        if (response.status != 200) {
          this.isError = true;

          if (response.status == 0 || response.status == 502) {
            this.toastService.showError('Something went wrong with our servers',5000);
          }

          if (response.status == 503) {
            this.isMaintenance  = true;
          }
        }
        return response;
      })
      .then(response => response.json())
      .then((data: any) => {
        this.isLoading = false;
        if(this.isError){
          this.INVOICE_NOT_FOUND = data.type == 'INVOICE_NOT_FOUND';
          if(this.isMaintenance){
            this.maintenanceService.maintenance(data.message);
          }
        }else {
          this.invoice = data.invoice;
          this.order = data.order;
          this.order.orderItems?.sort((a, b) => a.description.localeCompare(b.description));
          if (this.isOrderCompleted()) {
            this.isPaid = true;
          }
        }
      }).catch(err => {
      this.isLoading = false;
      this.isError = true;
    });
  }

  onErrorPayment(errors: any) {
    this.isPaymentError = true;
    this.isLoading = false;

  }

  onSuccessPayment(data: any) {
    this.isPaid = true;
    this.isLoading = false;

  }

  isOrderCompleted() {
    return this.order.status == "COMPLETED";
  }

  getToName() {
    return this.invoice.template.templateMetas.filter((i: any) => i.key == 'to_name')[0].value;
  }

  getToAddress() {
    return this.invoice.template.templateMetas.filter((i: any) => i.key == 'to_address')[0].value;
  }

  getToContact() {
    return this.invoice.template.templateMetas.filter((i: any) => i.key == 'to_contact')[0].value;
  }

  getToEmail() {
    return this.invoice.template.templateMetas.filter((i: any) => i.key == 'to_email')[0].value;
  }

  getReferName() {
    return this.invoice.template.templateMetas.filter((i: any) => i.key == 'refer_name')[0].value;
  }

  getReferPosition() {
    return this.invoice.template.templateMetas.filter((i: any) => i.key == 'refer_position')[0].value;
  }

  getReferEmail() {
    return this.invoice.template.templateMetas.filter((i: any) => i.key == 'refer_email')[0].value;
  }

  getDueDate() {
    let dueDate = this.invoice.template.templateMetas.filter((i: any) => i.key == 'due_date');
    if (dueDate.length > 0) {
      return dueDate[0].value;
    }
    return null;
  }

  isFile() {
    return this.invoice.template.templateMetas.filter((i: any) => i.type == 'FILE').length > 0;
  }

  getFile() {
    return this.invoice.template.templateMetas.filter((i: any) => i.type == 'FILE')[0].value;
  }

  toPrice(amount: any) {
    return Price.toPrice(amount);
  }

  sanitizeName(extracName: any) {
    if (extracName == 'gateway_charge') {
      return 'Convenience fee';
    }
    return extracName;
  }
}



