import {Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, Input, HostListener} from '@angular/core';
import {URL} from "../../utils/URL";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {MaintenanceService} from "../../service/maintenance/maintenance.service";


// const SCRIPT_URL = 'https://test-gateway.mastercard.com/form/version/58/merchant/TREINETIC/session.js';
declare var ClearPay: any;
declare var $: any;

@Component({
  selector: 'app-payment-view',
  templateUrl: './payment-view.component.html',
  styleUrls: ['./payment-view.component.scss']
})
export class PaymentViewComponent implements OnInit {

  _PARAMS_: any = null;
  authenticationUrl: any = null;
  scriptUrl: any = null;
  authenticatePayerUrl: any = null;
  operationType: any = null;
  checkOrderUrl: any = null;
  addCardUrl: any = null;
  clearPay: any = null;
  isLoading = true;
  threeDsHtml: any = null;
  isCardDetailsError = false;
  cardDetailsErrorMessages: any = null;
  modalRef: any = null;

  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() openThreeDS: EventEmitter<any> = new EventEmitter();
  @Output() closeThreeDS: EventEmitter<any> = new EventEmitter();
  @Input() buttonText: any = null;
  @ViewChild('threeDsModel') threeDsModel: any;
  isMaintenance = false;
  isError = false;
  @Input() url;

  constructor(private modalService: NgbModal, private maintenanceService: MaintenanceService) {

  }

  ngOnInit(): void {
    this.fetchInvoice();
  }

  loadMGPScript(): void {
    const node = document.createElement('script');
    node.src = this.scriptUrl;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = () => {
      this.initializeMPG();
    };
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  initializeMPG(): void {

    this.clearPay = new ClearPay({
      elements: {
        cardNumberField: '#card-number',
        securityCodeField: '#security-code',
        expiryMonthField: '#expire-month',
        expiryYearField: '#expire-year',
        nameOnCardField: '#card-holder-name'
      },
      events: {
        onInitializing: () => {
        },
        onInitialized: (status: any) => {
          this.isLoading = false;
        },
        onInitiatePayment: () => {
        },
        onPaymentDetailsError: (errors: string | string[]) => {
          console.log('payment details errors', errors);
          this.showPaymentDetailsErrors(errors);
          parent.postMessage('cp-on-error', '*');
        },
        onPaymentSystemError: (errors: any) => {
          console.log('on payment system error', errors);
          this.showPaymentProcessError(errors);
        },
        onInitiateAuthentication: () => {
          console.log('on initiate authentication');
        },
        onInitiateAuthenticationReady: (html: any) => {
          $('#initialScriptHtml').html(html);
        },
        onThreeDSReady: (html: any) => {
          setTimeout(() => {
            this.openThreeDS.emit();
            $('#threeDSUI').html(html);
          }, 100);
          this.openThreeDs();
        },
        onSuccess: (data: any) => {
          console.log('on success called');
          this.modalRef.close();
          this.onSuccess.emit(data);
        }
      },
      url: {
        initiateAuthenticationUrl: this.authenticationUrl,
        authenticatePayerUrl: this.authenticatePayerUrl,
        getOrderUrl: this.checkOrderUrl,
        addCardUrl: this.addCardUrl
      },
      data: this._PARAMS_
    });
    this.clearPay.initialize();
  }

  fetchInvoice(): any {

    fetch(this.url)
      .then((response) => {
        this.maintenanceService.maintenance(null);
        this.isMaintenance = false;
        this.isError = false;

        if (response.status != 200) {
          this.isError = true;
          if (response.status == 503) {
            this.isMaintenance = true;
            return response;
          }
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        if (this.isError) {
          if (this.isMaintenance) {
            this.maintenanceService.maintenance(data.message);
          }
        } else {
          this._PARAMS_ = JSON.parse(data.data);
          this.authenticationUrl = data.authenticationUrl;
          this.authenticatePayerUrl = data.authenticatePayerUrl;
          this.operationType = data.operationType;
          this.checkOrderUrl = data.checkOrderUrl;
          this.addCardUrl = data.addCardUrl;
          this.scriptUrl = data.gatewayUrl + '/session.js';
          this.loadMGPScript();
        }
      }).catch(err => {
      this.isLoading = false;
      this.isError = true;
      this.onError.emit();
    });
  }

  initiatePayment(): any {
    this.isLoading = true;
    this.isCardDetailsError = false;
    this.clearPay.pay();
  }

  openThreeDs() {
    this.modalRef = this.modalService.open(this.threeDsModel, {
      size: 'l', centered: true, keyboard: false, beforeDismiss: () => {
        return false;
      }
    });
  }

  showPaymentDetailsErrors(errors: any) {
    // 'CARD_NUMBER_INVALID_MISSING', 'EXPIRY_YEAR_INVALID_MISSING', 'EXPIRY_MONTH_INVALID_MISSING'
    this.cardDetailsErrorMessages = [];
    if (errors.includes('CARD_NUMBER_INVALID_MISSING')) {
      this.cardDetailsErrorMessages.push('Card number is invalid or missing');
    }
    if (errors.includes('EXPIRY_YEAR_INVALID_MISSING')) {
      this.cardDetailsErrorMessages.push('Expire year is invalid or missing');
    }
    if (errors.includes('EXPIRY_SECURITY_CODE_MISSING')) {
      this.cardDetailsErrorMessages.push('Security code is invalid or missing');
    }
    if (errors.includes('EXPIRY_MONTH_INVALID_MISSING')) {
      this.cardDetailsErrorMessages.push('Expire month is invalid or missing');
    }
    this.isCardDetailsError = true;
    this.isLoading = false;
  }

  showPaymentProcessError(errors: any) {
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.isLoading = false;
    this.onError.emit(errors);
  }

}
