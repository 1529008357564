<div class="row mt-1">
  <div class="col"></div>
  <div style="max-width: fit-content" class="col-2 "><img class="secure-img" src="assets/secure/visa-master.png" alt=""></div>
  <div style="max-width: fit-content" class="col-2 "><img class="secure-img" src="assets/secure/pci.png" alt=""></div>
  <div style="max-width: fit-content" class="col-2 "><img class="secure-img" src="assets/secure/ssl.jpeg" alt=""></div>
</div>

<div class="row mt-3">
  <div class="col-12 derails-value">Please fill in the following information to continue with the payment</div>
  <div class="col"></div>
</div>
<div class="payment-detail-container p-2">
  <div class="card-info-container mt-3">
    <div class="row">
      <div class="col">
        <div class="form-group card-holder">
          <input type="text" class="form-control " id="card-holder-name" placeholder="Card Holder" readonly>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group card-number">
          <input type="text" class="form-control " id="card-number" placeholder="Card Number" readonly>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="" style="width: 33%;">
        <div class="form-group exp-month">
          <input type="text" class="form-control " id="expire-month" placeholder="MM" readonly>
        </div>
      </div>
      <div class="" style="width: 33%">
        <div class="form-group exp-year">
          <input type="text" class="form-control " id="expire-year" placeholder="YY" readonly>
        </div>
      </div>
      <div class="" style="width: 33%">
        <div class="form-group cvv">
          <input type="text" class="form-control " id="security-code" placeholder="CVV" readonly>
        </div>
      </div>
    </div>

  </div>
  <button class="btn pay-btn btn-block mt-4" (click)="initiatePayment()"> Pay {{buttonText}}</button>

  <p class="errors" *ngIf="isCardDetailsError">
    <span *ngFor="let m of cardDetailsErrorMessages">
      {{m}} <br>
    </span>
  </p>


  <div class="loader d-flex justify-content-center align-items-center" *ngIf="isLoading">
    <div class="spinner-border" style="width: 1.5rem; height: 1.5rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>


</div>

<div id="initialScriptHtml" style="display: none"></div>
<ng-template #threeDsModel let-modal>
  <div class="modal-body">
    <div class="threeDSUI" id="threeDSUI" style="height: 700px">

    </div>
  </div>
</ng-template>

