import {Component, HostListener, Input, OnInit} from '@angular/core';
import {Params} from "../../utils/Params";
import {Price} from "../../utils/Price";

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {
  url: RequestInfo;
  order: any;
  email: any;
  @Input() pageType = null;
  @Input() closeTxt = null;

  constructor() {
    this.url = Params.get('u');
    this.closeTxt = Params.get('c');
    this.pageType = Params.get('p') ?? 'web';
    this.fetchOrder();
  }

  ngOnInit(): void {
  }

  private fetchOrder() {
    fetch(this.url)
      .then(response => {
        return response;
      })
      .then(response => response.json())
      .then((data: any) => {
        this.order = data.order;
        console.log(this.order?.currency);
      }).catch(err => {

    });
  }

  toPrice(amount: any) {
    return Price.toPrice(amount);
  }

  onErrorPayment($event: any) {
    if ($event != null && $event != 'undefined') {
      window.top.postMessage(
        {
          cp_error: $event,
          data: {}
        }, '*'
      );
    }
  }


  onSuccessPayment($event: any) {
    window.top.postMessage(
      {
        cp_success: 'SUCCESS',
        data: $event
      }, '*'
    );
  }

  openThreeDS() {

  }

  cpDismiss() {
    window.top.postMessage(
      {
        cp_close: true,
        data: {}
      }, '*'
    );
  }

  getKeyValue(key: string) {
    return this.order.orderMeta.filter((i: any) => i.key == key)[0].value;

  }
}
