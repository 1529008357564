<app-maintenance></app-maintenance>
<div class="container-fluid body d-flex justify-content-center">

  <div class="loader d-flex justify-content-center align-items-center" *ngIf="isLoading">
    <div class="spinner-border" style="width: 1.5rem; height: 1.5rem; color: #888888" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="inner-container" *ngIf="isError">
    <div class="card shadow-card payment-info  mt-3" >
      <div class="card-body">
        <div class="message-content d-flex align-items-center flex-column" >
          <i class="fa fa-exclamation error-ico ico"></i>
          <p class="msg-title error-title">
            {{PAYLINK_NOT_FOUND? 'Invalid pay link':'Something went wrong'}}</p>
          <p class="text-center font-grey-2">
            {{PAYLINK_NOT_FOUND? 'This pay link is no longer available.':
            'Something went wrong with your request. Please try again if the problem persists contact us via info@clearpay.com'}}
            </p>
        </div>
      </div>
    </div>
  </div>

  <div class="inner-container" *ngIf="paylink != null && order != null">
    <div class="w-100 text-center mb-2">
      <img class="merchant-logo" src="{{paylink.template.merchant.businessProfile.logo}}" alt="">

    </div>
    <h2 class="merchant-name">
      {{
      paylink.template.merchant.businessProfile.name
      }}
    </h2>

    <div class="card shadow-card mt-3">
      <div class="card-body">
        <h1 class="total-amount">
          {{order.currency}} {{toPrice(order.netTotal)| number: '.2'}}
        </h1>
        <p class="due-date font-grey-2">
          <span>Due on {{getDueDate()}}</span>
        </p>

        <table class="meta-info-table">
          <tr>
            <td>
              <p class="font-grey-2 mr-3">
                To
              </p>
            </td>
            <td>
              {{getToName() | truncate:[35]}} <br>
              {{getToContact() | truncate:[35]}} <br>
              {{getToEmail() | truncate:[35]}}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="card shadow-card  mt-3">
      <div class="card-body">
        <h5>
          Paylink details
        </h5>
        <div class="row mt-3">
          <div class="col">
            <img src="{{getImage()}}" alt="" class="img-fluid  paylink-image">
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <p class="service-name mb-0">{{getName()}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p class="font-grey-2 mt-0">{{getDescription()}}</p>
          </div>
        </div>


        <hr>
        <table class="invoice-items mt-1">
          <tr>
            <td class="item-description ">
              <p class="item-name mt-3">Amount Due</p>
            </td>
            <td class="item-price">
              <p class="item-net-price">{{order.currency}} {{toPrice(order.netTotal)| number: '.2'}}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>


    <div class="card shadow-card payment-info  mt-3" *ngIf="!isPaid">
      <div class="card-body">

        <app-payment-view [url]="order.orderApiUrl"  [buttonText]="order.currency + ' ' + (toPrice(order.netTotal)| number: '.2')"
                          (onError)="onErrorPayment($event)" (onSuccess)="onSuccessPayment($event)"></app-payment-view>

      </div>
    </div>

    <div class="card shadow-card payment-info  mt-3" *ngIf="isPaid || isPaymentError">
      <div class="card-body">
        <div class="message-content d-flex align-items-center flex-column" *ngIf="isPaid">
          <i class="fa fa-check-circle success-ico ico"></i>
          <p class="msg-title success-title">Paid successfully</p>
        </div>

        <div class="message-content d-flex align-items-center flex-column" *ngIf="isPaymentError">
          <i class="fa fa-exclamation error-ico ico"></i>
          <p class="msg-title error-title">Something went wrong</p>
          <p class="text-center font-grey-2">Something went wrong with your payment. Please try again. If the problem persists please contact us. </p>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <p class="font-grey-2 support-text">
        If you need any help or support please contact <a href="mailto:merchant@clearpay.com" target="_blank" >merchant@clearpay.com</a>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <span class="font-grey-2 powered-by">Powered By</span> <img
      src="assets/logo.png" alt="" class="clearpay-logo">
    </div>

  </div>

</div>
