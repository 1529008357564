import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InvoiceComponent} from './views/invoice/invoice.component';
import {PaylinkComponent} from "./views/paylink/paylink.component";
import {PayComponent} from "./views/pay/pay.component";

const routes: Routes = [
  {path: 'invoice.html', component: InvoiceComponent},
  {path: 'paylink.html', component: PaylinkComponent},
  {path: 'pay', component: PayComponent},
  {path: 'pay.html', component: PayComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
