import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceComponent } from './views/invoice/invoice.component';
import { PaymentViewComponent } from './components/payment-view/payment-view.component';
import { PaylinkComponent } from './views/paylink/paylink.component';
import { TruncatePipe } from './utils/truncate.pipe';
import { MaintenanceComponent } from './views/maintenance/maintenance.component';
import { PayComponent } from './views/pay/pay.component';

@NgModule({
    declarations: [
        AppComponent,
        InvoiceComponent,
        PaymentViewComponent,
        PaylinkComponent,
        TruncatePipe,
        MaintenanceComponent,
        PayComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
