<app-maintenance></app-maintenance>
<div class="container-fluid body d-flex justify-content-center">

  <div class="loader d-flex justify-content-center align-items-center" *ngIf="isLoading">
    <div class="spinner-border" style="width: 1.5rem; height: 1.5rem; color: #888888" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="inner-container" *ngIf="isError">
    <div class="card shadow-card payment-info  mt-3">
      <div class="card-body">
        <div class="message-content d-flex align-items-center flex-column">
          <i class="fa fa-exclamation error-ico ico"></i>
          <p class="msg-title error-title">
            {{INVOICE_NOT_FOUND? 'Invalid invoice':'Something went wrong'}}</p>
          <p class="text-center font-grey-2">
            {{INVOICE_NOT_FOUND? 'This invoice is no longer available.':
            'Something went wrong with your request. Please try again if the problem persists contact us via info@clearpay.com'}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="inner-container" *ngIf="invoice != null && order != null">
    <div class="w-100 text-center mb-2">
      <img class="merchant-logo" src="{{invoice.template.merchant.businessProfile.logo}}" alt="">

    </div>
    <h2 class="merchant-name">
      {{
      invoice.template.merchant.businessProfile.name
      }}
    </h2>
    <p class="invoice-no font-grey-2">Invoice #{{invoice.invoiceNumber}}</p>

    <div class="card shadow-card mt-3">
      <div class="card-body">
        <h1 class="total-amount">
          {{order.currency}} {{toPrice(order.netTotal)| number: '.2'}}
        </h1>
        <p class="due-date font-grey-2">
          <span *ngIf="getDueDate() != null">Due on {{getDueDate()}}</span>
        </p>

        <table class="meta-info-table">
          <tr>
            <td>
              <p class="font-grey-2 mr-3">
                To
              </p>
            </td>
            <td>
              {{getToName() | truncate:[35]}} <br>
              {{getToAddress() | truncate:[35]}} <br>
              {{getToContact() | truncate:[35]}} <br>
              {{getToEmail() | truncate:[35]}}
            </td>
          </tr>
          <tr>
            <td class="pt-3">
              <p class="font-grey-2 mr-3">
                Refer
              </p>
            </td>
            <td class="pt-3">
              {{getReferName() | truncate:[35]}} <br>
              {{getReferPosition() | truncate:[35]}} <br>
              {{getReferEmail() | truncate:[35]}}
            </td>
          </tr>
        </table>

        <hr class="mt-4">
        <p class="view-invoice" (click)="showInvoice = !showInvoice">
          {{showInvoice ? 'Hide' : 'View'}} detail invoice
        </p>

      </div>
    </div>

    <div class="card shadow-card  mt-3" *ngIf="!isFile() && showInvoice">
      <div class="card-body">
        <h5>
          Invoice details
        </h5>

        <div class="invoice-items mt-3">
          <div *ngFor="let item of order.orderItems" class="invoice-item-container mt-1">
            <div class="item-description">
              <p class="item-name">{{item.description}}</p>
              <p class="item-qty font-grey-2">Qty {{item.qty}}</p>
            </div>
            <div class="item-price">
              <p class="item-net-price">{{order.currency}} {{toPrice(item.totalPrice)| number: '.2'}}</p>
              <p class="item-unit-price font-grey-2">{{order.currency}} {{toPrice(item.unitPrice)| number: '.2'}} unit price</p>
            </div>
          </div>
        </div>
        <hr>
        <div class="invoice-items mt-3">
          <div class="invoice-item-container">
            <div class="item-description">
              <p class="item-name">Sub total</p>
            </div>
            <div class="item-price">
              <p class="item-net-price">{{order.currency}} {{toPrice(order.grossTotal)| number: '.2'}}</p>
            </div>
          </div>

          <div *ngFor="let item of invoice.template.templateExtras" class="invoice-item-container">
            <div class="item-description">
              <p class="item-extra-name font-grey-2">{{sanitizeName(item.name)}}</p>
            </div>
            <div class="item-price">
              <p class="item-extra-price font-grey-2">{{order.currency}} {{toPrice(item.unitPrice)| number: '.2'}}</p>
            </div>
          </div>

          <div class="invoice-item-container mt-3">
            <div class="item-description ">
              <p class="item-name ">Amount Due</p>
            </div>
            <div class="item-price">
              <p class="item-net-price">{{order.currency}} {{toPrice(order.netTotal)| number: '.2'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-card  mt-3" *ngIf="isFile() && showInvoice">
      <div class="card-body">
        <h5>
          Invoice details
        </h5>

        <div class="download-invoice d-flex align-items-center justify-content-center">
          <a class="p-3 download-invoice-btn" href="{{getFile()}}" target="_blank">
            Download invoice <i class="fa fa-arrow-down"></i>
          </a>
        </div>

        <hr>
        <div class="invoice-items mt-3">
          <div class="invoice-item-container">
            <div class="item-description">
              <p class="item-name">Sub total</p>
            </div>
            <div class="item-price">
              <p class="item-net-price">{{order.currency}} {{toPrice(order.grossTotal)| number: '.2'}}</p>
            </div>
          </div>

          <div *ngFor="let item of invoice.template.templateExtras" class="invoice-item-container">
            <div class="item-description">
              <p class="item-extra-name font-grey-2">{{sanitizeName(item.name)}}</p>
            </div>
            <div class="item-price">
              <p class="item-extra-price font-grey-2">{{order.currency}} {{toPrice(item.unitPrice)| number: '.2'}}</p>
            </div>
          </div>

          <div class="invoice-item-container mt-3">
            <div class="item-description">
              <p class="item-name">Amount due</p>
            </div>
            <div class="item-price">
              <p class="item-net-price">{{order.currency}} {{toPrice(order.netTotal)| number: '.2'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-card payment-info  mt-3" *ngIf="!isPaid && !isOrderCompleted()">
      <div class="card-body">

        <app-payment-view [url]="order.orderApiUrl"
                          [buttonText]="order.currency + ' ' + (toPrice(order.netTotal)| number: '.2')"
                          (onError)="onErrorPayment($event)"
                          (onSuccess)="onSuccessPayment($event)"></app-payment-view>

      </div>
    </div>

    <div class="card shadow-card payment-info  mt-3" *ngIf="isPaid || isPaymentError">
      <div class="card-body">
        <div class="message-content d-flex align-items-center flex-column" *ngIf="isPaid">
          <i class="fa fa-check-circle success-ico ico"></i>
          <p class="msg-title success-title">Paid successfully</p>
        </div>

        <div class="message-content d-flex align-items-center flex-column" *ngIf="isPaymentError">
          <i class="fa fa-exclamation error-ico ico"></i>
          <p class="msg-title error-title">Something went wrong</p>
          <p class="text-center font-grey-2">Something went wrong with your request. Please try again if the problem persists contact us via info@clearpay.com
          </p>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <p class="font-grey-2 support-text">
        If you need any help or support please contact <a href="mailto:merchant@clearpay.com" target="_blank" >merchant@clearpay.com</a>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <span class="font-grey-2 powered-by">Powered By</span> <img
      src="assets/logo.png" alt="" class="clearpay-logo">
    </div>


  </div>

</div>
