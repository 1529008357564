import {environment} from "../../environments/environment";
import {ENV} from "./ENV";

export class URL {


  static getPaymentUrl(id: any): string {
    return URL.getServer() + environment.PAYMENT_ENDPOINT + id;
  }

  static getInvoice(id: any): string {
    return URL.getServer() + environment.INVOICE_ENDPOINT + id;
  }

  static getPaylink(id: any): string {
    return URL.getServer() + environment.PAYLINK_ENDPOINT + id;
  }

  static getServer(): string {
    if (ENV.isSandbox()) {
      return environment.SB_SERVER;
    }
    return environment.LIVE_SERVER;
  }


}
